import React, {useState, useEffect} from "react";
import {Table, Button, tbody, th, thead, tr, Col, Alert} from "reactstrap";
import axios from "axios";
import {useUser} from "../context/user";
import {API_URL} from "../config";
import moment from "moment";

function Retailers(props) {
    const {dabblUser } = useUser();
    const [data, setData] = useState(null);
    const [requestError, setRequestError] = useState('');


    function requestUserRetailers(){
        if (!dabblUser || !dabblUser.id) { return }
        axios.get(`/user/${dabblUser.id}/retailers`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setData(result.data);
                }
            }).catch(e => {
            setRequestError(e.message)
            console.error(e.message)
        });
    }

    function copyToClipboard(copy) {

    }

    useEffect(() => {
        if (!dabblUser) { return }
        requestUserRetailers();
    }, [dabblUser]);

    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>Date</th>
            <th>Retailer</th>
            <th>Status</th>
            <th>Username</th>
            <th>Lookback</th>
            <th>Error</th>
            {/*<th>Uri</th>*/}
            <th>Unlock Url</th>
        </tr>
        </thead>
        <tbody>{requestError && <Col><Alert color="danger">{requestError}</Alert></Col>}
        {data === null
            ? <tr><td>Loading...</td></tr>
            : data.map((row, index) => {
                return <tr key={index}>
                    <th scope="row">{row.createddate ? moment.utc(row.createddate).format('YYYY-MM-DD HH:mm') : '-'}</th>
                    <td>{row.name}</td>
                    <td>{row.status}</td>
                    <td>{row.username}</td>
                    <td>{row.lookback}</td>
                    <td>{row.error}</td>
                    <td>{row.unlockurl ? <Button color='link' onClick={(e)=> { e.preventDefault(); navigator.clipboard.writeText(row.unlockurl);}}>Copy</Button> : ''}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default Retailers;