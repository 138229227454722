import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import axios from 'axios';
import {useAuth} from "../context/auth";
import logoImage from '../assets/dabbl-logo-orange.png';


function Login(props) {
  console.log(props.location);
  const referer = props.location.state ? props.location.state.referer : '/';
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthUser } = useAuth();

  function onSubmit(e) {
    e.preventDefault();
    axios.create({
      auth: { username: username, password: password }
    }).post('/login', {
      username,
      password
    }).then(result => {
      if (result.status === 200) {
        setAuthUser({token: result.data, username: username});
        setLoggedIn(true);
      } else {
        setRequestError('Error occurred on backend side.');
      }
    }).catch(e => {
      if (e.response && e.response.status === 401) {
        setRequestError('Unknown user credentials, please contact someone that can give you proper credentials');
      } else {
        setRequestError(e.message);
      }
    });
  }

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
      <Container>
        <Row>
          <Col sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 } }>
            <Card>
              <CardImg top style={{width: '100px', margin: '30px auto', display: 'block'}} src={logoImage} alt="Logo Image" />
              <CardBody>
                <CardTitle>Sign In Form</CardTitle>
                <Form onSubmit={onSubmit}>
                  <Col>
                    <FormGroup>
                      <Label>Username</Label>
                      <Input
                          type="email"
                          name="username"
                          id="username"
                          placeholder="email@getdabbl.com"
                          value={ username }
                          valid={ isValidEmail }
                          invalid={ !isValidEmail }
                          onChange={ (e) => {
                            setIsValidEmail(emailRex.test(e.target.value));
                            setUsername(e.target.value)
                          } }
                      />
                      <FormFeedback valid>
                        Email is valid
                      </FormFeedback>
                      <FormFeedback>
                        Invalid email. Please input a correct email.
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="examplePassword">Password</Label>
                      <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="********"
                          value={ password }
                          onChange={ (e) => setPassword(e.target.value) }
                      />
                    </FormGroup>
                  </Col>
                  {requestError &&
                  <Col>
                    <Alert color="danger">
                      {requestError}
                    </Alert>
                  </Col>
                  }
                  <Button color="primary" className='float-right'>Submit</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  );
}

export default Login;