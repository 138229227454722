import React from "react";
import {Button, Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function EarningsStreak(props) {
    const data = props.data;

    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>#</th>
            <th>Created Date</th>
            <th>Streak Date</th>
            <th>Streak Count</th>
            <th>Bonus</th>
            <th>Earning</th>
            <th>Paid</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{moment.utc(row.transactiondate).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{moment.utc(row.streakdate).format('YYYY-MM-DD')}</td>
                    <td>{row.streakcount} &nbsp; {props.source === 'maindb' && <Button color="link" className='p-0 align-baseline' onClick={(e) => {e.preventDefault();props.continueStreak(moment.utc(row.streakdate).add(1, 'day').format('YYYY-MM-DD'), row.streakcount + 1)}}>EXTEND</Button> }</td>
                    <td>{row.bonus }</td>
                    <td>{row.earning && row.earning !== '' ? `$${row.earning}` : '-'}</td>
                    <td>{row.useramount && row.useramount !== '' ? `$${row.useramount}` : '-'}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default EarningsStreak;
