import React from "react";
import {Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function EarningsPromo(props) {
    const data = props.data;
    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>#</th>
            <th>Transaction Date</th>
            <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{moment.utc(row.transactiondate).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{row.useramount && row.useramount !== '' ? `$${row.useramount}` : '-'}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default EarningsPromo;
