import React, {useState, useEffect} from "react";
import {Table, Button, tbody, th, thead, tr, Col, Alert} from "reactstrap";
import moment from "moment";

function VerifiedPurchases(props) {
    const verifiedPurchases = props.verifiedPurchases || [];

    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>Verified Purchase</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>Expiration Date</th>
            <th>Credit Type</th>
            <th>Value per User</th>
            <th>Progress</th>
        </tr>
        </thead>
        <tbody>
        {verifiedPurchases.map((row, index) => {
                return <tr key={index}>
                    <th scope="row">{row.productdescription}</th>
                    <td>{row.status}</td>
                    <td>{row.startdate ? moment.utc(row.startdate).format('YYYY-MM-DD') : '-'}</td>
                    <td>{row.expirationdate ? moment.utc(row.expirationdate).format('YYYY-MM-DD') : '-'}</td>
                    <td>{row.credittype === 'COUNT_THRESHOLD' ? 'Items Count' : 'Dollar Amount' }</td>
                    <td>{row.valueperuser}</td>
                    <td>{row.credittype === 'COUNT_THRESHOLD' ? `${row.qty % row.threshold} of ${row.threshold}` : `$${row.amount % row.threshold} of $${row.threshold}` }</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default VerifiedPurchases;