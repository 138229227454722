import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import * as d3 from "d3";
import {colors} from '../config'
import moment from 'moment';
const colorRange = [colors.dabbl, colors.ad, colors.survey, colors.promo, colors.referral, colors.receipt]

const margin = {top: 10, right: 0, bottom: 60, left: 30}

function EarningsChart(props) {
    const data = props.data.map(({couponAwarded,...d})=>({...d, createddate: moment.utc(d.createddate)}));
    const svgRef = useRef();
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(600)
    const [hoverIndex, setHoverIndex] = useState(null)
    const [tooltipXY, setTooltipXY] = useState({})

    useLayoutEffect( () => {
        if (width === svgRef.current.parentElement.offsetWidth) return;
        setWidth(svgRef.current.parentElement.offsetWidth)
        setHeight(svgRef.current.parentElement.offsetWidth / 16 * 9)
        }, [width])

    useEffect(() => {
        d3.select(svgRef.current).selectAll("*").remove();
        d3.json(data)
        const dataTotal = data.map (b => (+b.credited||0) + (+b.creditedAd||0)  + (+b.creditedSurvey||0)  + (+b.creditedPromo||0) + (+b.creditedReferral||0) + (+b.creditedReceipt||0) + (+b.creditedTransfer||0), 0)
        const series = d3.stack().keys(Object.keys(data[0]).filter(k => k !== 'createddate'))(data)
        const x = d3.scaleBand()
            .domain(data.map(d => d.createddate).reverse())
            .range([margin.left, width - margin.right])
            .padding(0.1)
        const y = d3.scaleLinear()
            .domain([0, d3.max(dataTotal)])
            .rangeRound([height - margin.bottom, margin.top])

        const color = d3.scaleOrdinal()
            .range(colorRange);

        const xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(d3.timeFormat("%Y-%m-%d")))
            .call(g => g.selectAll(".domain").remove())
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", function(d) {
                return "rotate(-65)"
            });
        const yAxis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y).ticks(null, "s"))
            .call(g => g.selectAll(".domain").remove())

        const svg = d3.select(svgRef.current)
        svg.attr("viewBox", [0, 0, width, height])
            .append("g")
            .selectAll("g")
            .data(series)
            .join("g")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .join("rect")
            .attr("x", (d, i) => x(d.data.createddate))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth())
            .on('mouseenter', function (d, i) {
                setTooltipXY({x: d3.event.pageX, y: d3.event.pageY})
                setHoverIndex(i)
                setTimeout(()=> setHoverIndex(null), 4000)
            })
        svg.append("g").call(xAxis);
        svg.append("g").call(yAxis);
    }, [width, height]);

    return <div><svg ref={svgRef}/>
        {hoverIndex !== null && <div style={{left:tooltipXY.x+'px', top: (tooltipXY.y-window.scrollY)+'px', position: 'fixed', background: 'white', border:'1px solid black', borderRadius:'8px', pointerEvents:'none', fontSize:'0.75rem', padding:'4px'}}>
            {data[hoverIndex].createddate.format('YYYY-MM-DD')}<br/>
            Dabbl: {data[hoverIndex].credited}<br/>
            Ad: {data[hoverIndex].creditedAd}<br/>
            Survey: {data[hoverIndex].creditedSurvey}<br/>
            Offer: {data[hoverIndex].creditedWebOffer}<br/>
            Promo: {data[hoverIndex].creditedPromo}<br/>
            Referral: {data[hoverIndex].creditedReferral}<br/>
            Receipt: {data[hoverIndex].creditedReceipt}<br/>
            Transfer: {data[hoverIndex].creditedTransfer}<br/>
        </div> }
    </div>
}

export default EarningsChart;