export const API_URL = process.env.REACT_APP_SUPPORT_URL;
export const CLUSTER_IP = process.env.REACT_APP_CLUSER_IP;
export const STREAK_PORT = "32228";
export const APP_VERSION = process.env.REACT_APP_VERSION || 'not set';
export const USER_KEY = "token";

export const earningTitles = {
    dabbl: 'Dabbl',
    research: 'Research',
    ad: "Ad",
    survey: "Survey",
    offer: "Offer",
    streak: "Streak",
    promo: "Bonus",
    referral: "Referral",
    register: "Registration",
    receipt: "Receipt",
    total: "Total",
    program: "Program"
};


export const colors = {
    dabbl: '#E86123',
    research: '#f89143',
    ad: "#FF2222",
    survey: "#dddd23",
    offer: "#dd33dd",
    promo: "#39AC49",
    referral: "#286DAF",
    receipt: "#e8002F",
    program: "#294113"
};

export const appUserStatuses = {
    ACTIVE: null,
    AD_AUTOMATION_BLOCK: 'AD_AUTOMATION_BLOCK',
    BLOCKED: 'BLOCKED',
    CONCURRENT_IP: 'CONCURRENT_IP',
    EMAIL_BLOCKED: 'EMAIL_BLOCKED',
    FAST_SURVEY: 'FAST_SURVEY',
    INFLUENCER_BLOCK: 'INFLUENCER_BLOCKED',
    MAX_ADID: 'MAX_ADID',
    NOT_MOBILE: 'NOT_MOBILE',
    PIXALATE_BLOCK: 'PIXALATE_BLOCK',
    PIXALATE_REVIEW: 'PIXALATE_REVIEW',
    NON_US_TRAFFIC: 'NON_US_TRAFFIC'
}
