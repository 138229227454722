import React from "react";
import {Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function EarningsAd(props) {
    const data = props.data;

    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>#</th>
            <th>Transaction Date</th>
            <th>Vendor</th>
            <th>Amount Earned</th>
            <th>Revenue</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
            return <tr>
                <th scope="row">{index + 1}</th>
                <td>{moment.utc(row.transactiondate).format('YYYY-MM-DD HH:mm')}</td>
                <td>{row.vendor}</td>
                <td>{row.useramount && row.useramount !== '' ? `$${row.useramount}` : '-'}</td>
                <td>{row.revenue && row.revenue !== '' ? `$${row.revenue}` : '-'}</td>
            </tr>
        })}
        </tbody>
    </Table>
}

export default EarningsAd;
