import React from "react";
import {Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function EarningsReceipt(props) {
    const data = props.data;
    const source = props.source;
console.log(props)
    return source === 'esevent' ?
        <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>#</th>
            <th>Receipt Id</th>
            <th>Transaction Date</th>
            <th>Amount Credited</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{row.receiptid}</td>
                    <td>{row.transactiondate !== null ? moment.utc(row.transactiondate).format('YYYY-MM-DD HH:mm') : '-'}</td>
                    <td>{row.useramount && row.useramount !== '' ? `$${row.useramount} ${row.revenue > 0 ? '' : '(Default)'}` : '-'}</td>
                </tr>
            })}
        </tbody>
    </Table> :
        <Table size="sm" borderless hover responsive>
            <thead>
            <tr>
                <th>#</th>
                <th style={{width: "200px"}}>Receipt Id</th>
                <th>Created Date</th>
                <th>Retailer</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Brands</th>
                <th>Upcs</th>
            </tr>
            </thead>
            <tbody>
            {data === null
                ? <tr>Loading...</tr>
                : data.map((row, index) => {
                    return <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{row.receiptid}</td>
                        <td>{row.createddate !== null ? moment.utc(row.createddate).format('YYYY-MM-DD HH:mm') : '-'}</td>
                        <td>{row.retailer}</td>
                        <td>{row.status}</td>
                        <td>{row.amount && row.amount !== '' ? `$${row.amount}` : row.status === 'CREDITED' ? 'Default' : '-'}</td>
                        <td>{row.brands && <div dangerouslySetInnerHTML={{__html: row.brands.split(",").join("<br/>")}} />}</td>
                        <td>{row.upcs && <div dangerouslySetInnerHTML={{__html: row.upcs.split(",").join("<br/>")}} />}</td>
                    </tr>
                })}
            </tbody>
        </Table>
}

export default EarningsReceipt;
