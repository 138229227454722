import React, {useEffect, useState} from "react";
import {Redirect} from 'react-router-dom'
import {useUser} from "../context/user"
import axios from "axios";
import {Alert, Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import EarningsTable from "../components/EarningsTable";
import EarningsChart from "../components/EarningsChart";
import ChartGiftCard from "../components/ChartGiftCard";
import ChartBalance from "../components/ChartBalance";

function UserCharts(props) {
    const {dabblUser} = useUser();
    const [days, setDays] = useState(30);
    const [isLoading, setIsLoading] = useState(false);
    const [requestError, setRequestError] = useState('');
    const [data, setData] = useState(null);
    const balance = props.location.state.balance || 0;

    useEffect(() => {
        if (dabblUser === null) { return; }
        setIsLoading(true)
        axios.get(`/user/${dabblUser.id}/earningsbyday?days=${days}`)
            .then(result => {
                if (result.status === 200 && !result.data.error) {
                    setData(result.data);
                } else {
                    setRequestError('Error occurred on backend side.');
                }
                setIsLoading(false)
            }).catch(e => {
            setRequestError(e.message);
            setIsLoading(false)
        });
    }, [days, dabblUser]);
    if (dabblUser === null) {
        return <Redirect to='/userlookup' />
    }

    return  <Container>
        <Row>
            <Col sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }}>
                <Card>
                    <CardBody>
                        <Row>
                            <Nav tabs className='w-100'>
                                <NavItem> <NavLink active={days === 10} onClick={() => { setDays(10); }} > Last 10 days </NavLink></NavItem>
                                <NavItem> <NavLink active={days === 30} onClick={() => { setDays(30); }} > Last 30 days </NavLink></NavItem>
                                <NavItem> <NavLink active={days === 90} onClick={() => { setDays(90); }} > Last 90 days </NavLink></NavItem>
                                <NavItem> <NavLink active={days === 180} onClick={() => { setDays(180); }} > Last 180 days </NavLink></NavItem>
                            </Nav>
                        </Row>
                        {data !== null && !isLoading && data.length > 0 &&
                        <React.Fragment>
                            <Row><Col className='d-flex justify-content-center'>Balance at the Beginning of the Day</Col></Row>
                            <Row><Col><ChartBalance data={data} balance={balance} /></Col></Row>
                            <Row><Col className='d-flex justify-content-center'>Gift Cards Redemption Values</Col></Row>
                            <Row><Col><ChartGiftCard data={data}/></Col></Row>
                            <Row><Col className='d-flex justify-content-center'>Earnings per Day</Col></Row>
                            <Row><Col><EarningsChart data={data} /></Col></Row>
                            <Row><Col><EarningsTable data={data} /></Col> </Row>
                        </React.Fragment>
                        }
                        {data !== null && !isLoading && data.length === 0 && <Alert color="light">No Data Found</Alert>}
                        {requestError && <Row><Col> <Alert color="danger"> {requestError}</Alert></Col></Row> }
                        {isLoading && <Row><Col className='p-2'><h5>Loading...</h5></Col></Row> }
                        <Row>
                            <Col>
                                <Button color="primary" className='float-right' onClick={()=>props.history.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
}


export default UserCharts;