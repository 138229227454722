import React, { useState } from "react";
import {Label, Alert, Input, Container, Card, CardBody, CustomInput, Row, Col, Form, FormGroup, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import UserStatus from "./UserStatus";
import axios from "axios";
import {useUser} from "../context/user"
import {appUserStatuses} from "../config";

function LookupAdjustStatus(props) {
  const user = props.user;
  const userRefresh = props.userRefresh;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [status, setStatus] = useState(user.status?(user.status==='null'?'':user.status):'');
  const [comment, setComment] = useState('');
  const [requestError, setRequestError] = useState('');
  const {dabblUser, setDabblUser } = useUser();

  function onSubmitStatusChange(action, e) {
    e.preventDefault();
    console.log('Status change', e);
    console.log('Target value', e.target.value);
    axios.post(`/user/${user.id}/status`, {action, reason: status, comment: comment})
      .then(result => {
          if (result && result.status === 200) {
              setComment('');
              userRefresh(user.id)
          } else {
              setRequestError(result.data);
          }
      }).catch(e => {
        console.log('Error changing user ' + user.id + ' status to ' + status);
      });
  }

  return <React.Fragment>
      <h4>Update Status:</h4>
    <Card>
        <CardBody>
            <Row>
              <UserStatus user={user} labelSize="4"/>
            </Row>
            {requestError &&
            <Row>
              <Col>
                  <Alert color="danger">
                      {requestError}
                  </Alert>
              </Col>
            </Row>
            }
            <Row>
              <Col>
                <Form style={{width:'100%'}}>
                  <FormGroup row>
                      <Label sm={2}>Comment:</Label>
                      <Col sm={10}>
                              <Input
                                  type="text"
                                  name="comment"
                                  id="comment"
                                  placeholder='Comment or ticket number'
                                  value={ comment }
                                  onChange={ (e) => {
                                      setComment(e.target.value)
                                  } }
                              />
                      </Col>
                  </FormGroup>
                    <FormGroup row>
                        <Label for="selectStatus" sm={2}>Status</Label>
                        <Col sm={4}>
                            <CustomInput type="select" name="selectStatus"
                                         id='selectStatus'
                                         defaultValue={status}
                                         onChange={(e) => setStatus(e.target.value)}>
                                {Object.keys(appUserStatuses).map((k) => (
                                    <option key={k} value={k}>{appUserStatuses[k]}</option>
                                ))}
                            </CustomInput>
                        </Col>
                        <Col sm={4}>
                            <Button color='primary' disabled={!status || status===''} onClick={(e) => onSubmitStatusChange("block", e)}>Block</Button>
                            <Button color='primary' disabled={!status || status===''} onClick={(e) => onSubmitStatusChange("block", e)}>Whitelist</Button>
                        </Col>
                        <Col>
                            {!user.active || !user.authActive &&
                            <Button color='primary' style={{float: 'right'}} onClick={(e) => onSubmitStatusChange("unblock", e)}>Unblock</Button>
                            }
                        </Col>
                    </FormGroup>
                  <FormGroup row>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
        </CardBody>
    </Card>
  </React.Fragment>
}

export default LookupAdjustStatus;
