import React, {useEffect, useState} from "react";
import {Redirect, useHistory} from 'react-router-dom'
import {useIvt} from "../context/ivt"
import axios from "axios";
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row, Table,
    tbody,
    th,
    thead,
    tr
} from "reactstrap";
import IvtTable from "../components/IvtTable";

const initialSize = 200;
const sizeIncrement = 500;

function IvtPage(props) {
    // const {ivt, setIvt} = useIvt();
    const ivt={}
    const [isLoading, setIsLoading] = useState(false);
    const [size, setSize] = useState(initialSize);
    const [requestError, setRequestError] = useState('');
    const [data, setData] = useState(null);

    console.log("IVT PAGE")
    console.log(props)
    console.log(ivt)
    console.log(axios.defaults.baseURL)
    console.log(props.location.search)
    const search = props.location.search;
    if (!ivt) {
        const search = props.location.search;
            const params = new URLSearchParams(search);
            const phonenumber = params.get('phonenumber');
            const ip = params.get('ip');
    if (ip) {
        // setIvt({ip: ip})
    }
    if (phonenumber) {
        // setIvt({phonenumber: phonenumber})
    }
        console.log(phonenumber)
    }
    useEffect(() => {
        // if (!ivt) { return }
        setIsLoading(true)
        setRequestError(null);
        axios.get(`/ivt/ivthitlog${search}`)
            .then(result => {
                if (result.status === 200 && !result.data.error) {
                    setData(result.data);
                } else {
                    setRequestError('Error occurred on backend side.');
                }
                setIsLoading(false)
            }).catch(e => {
            setRequestError(e.message);
            setIsLoading(false)
        });
    }, [props, size]);

    function getSearchByFilter(data){
        let filters = []
        if (data.phonenumber !== undefined && data.phonenumber !== '') {
            filters.push(`phonenumber=${data.phonenumber}`)
        }
        if (data.ip !== undefined && data.ip !== '') {
            filters.push(`ip=${data.ip}`)
        }
        if (data.deviceid !== undefined && data.deviceid !== '') {
            filters.push(`deviceid=${data.deviceid}`)
        }
        if (data.userid !== undefined && data.userid !== '') {
            filters.push(`userid=${data.userid}`)
        }
        if (data.event !== undefined && data.event !== '') {
            filters.push(`event=${data.event}`)
        }
        return filters.length > 0 ? '?' + filters.join('&') : ''
    }

    function updateSize(newSize){
        setIsLoading(true);
        setSize(newSize)
    }

    return  <Container>
        <Row>
            <Col sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }}>
                <Card>
                    <CardBody>
                        {data !== null && !isLoading && data.length > 0 &&
                        <IvtTable data={data} lookupByFilter={(data) => { props.history.push({pathname:'/ivtPage', search:getSearchByFilter(data)});  }}/>
                        }
                        {data !== null && !isLoading && data.length === 0 && <Alert color="light">No Data Found</Alert>}
                        {requestError && <Row><Col> <Alert color="danger"> {requestError}</Alert></Col></Row> }
                        {isLoading && <Row><Col className='p-2'><h5>Loading...</h5></Col></Row> }
                        {data !== null && !isLoading && (data.length === size) &&
                        <Row>
                            <Col>
                                <Button color="link" className='justify-content-center'
                                        onClick={() => updateSize(size + sizeIncrement)}>Load More...</Button>
                            </Col>
                        </Row>
                        }
                        <Row>
                            <Col>
                                <Button color="primary" className='float-right' onClick={()=>props.history.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
}


export default IvtPage;