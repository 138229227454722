import React, {useEffect, useState} from "react";
import {Redirect} from 'react-router-dom'
import {useUser} from "../context/user"
import EarningsDabbl from '../components/EarningsDabbl'
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Fade,
    Form, FormFeedback, FormGroup, FormText,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import axios from "axios";
import {earningTitles, CLUSTER_IP, STREAK_PORT} from "../config";
import EarningsAd from "../components/EarningsAd";
import EarningsSurvey from "../components/EarningsSurvey";
import EarningsPromo from "../components/EarningsPromo";
import EarningsReferral from "../components/EarningsReferral";
import EarningsProgram from "../components/EarningsProgram";
import EarningsReceipt from "../components/EarningsReceipt";
import moment from "moment";
import EarningsStreak from "../components/EarningsStreak";

const initialSize = 200;
const sizeIncrement = 500;

function UserDetails(props) {
    const { dabblUser } = useUser();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [size, setSize] = useState(initialSize);
    const [source, setSource] = useState('esevent');
    const [extra, setExtra] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [filter, setFilter] = useState({from:'', to:'', extra:''});
    const [requestError, setRequestError] = useState('');
    const earningsType = props.location.state.earningsType || 'dabbl';


    useEffect(() => {
        if (dabblUser === null) { return; }
        requestData()
    }, [source, earningsType, size, dabblUser, filter]);
    if (dabblUser === null) {
        return <Redirect to='/userlookup' />
    }

    function requestData() {
        axios.get(`/user/${dabblUser.id}/earningtype/${earningsType}/source/${source}?size=${size}&from=${filter.from}&to=${filter.to}&param1=${filter.extra}`)
            .then(result => {
                if (result.status === 200 && !result.data.error) {
                    setData(result.data);
                    setRequestError('');
                } else {
                    setRequestError('Error occurred on backend side.');
                }
                setIsLoading(false)
            }).catch(e => {
            setRequestError(e.message);
            setIsLoading(false)
        });
    }

    function continueStreak(streakDate, streakCount) {
        axios.post(`/user/${dabblUser.id}/streak`, {streakDate: streakDate, streakCount: streakCount})
            .then(result => {
                if (result && result.status === 200) {
                    axios({
                      method: 'post',
                      url: `http://${CLUSTER_IP}:${STREAK_PORT}/streak/v1/credit`,
                      data: {
                        date: streakDate,
                        userId: dabblUser.id
                      }
                    }).then(result => {
                      if (!result || result.status !== 200) setRequestError(result.data);
                    }).catch( e => {
                      setRequestError(e.message);
                    })
                    requestData()
                } else {
                    setRequestError(result.data);
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }


    function getEarningsTable(){
        switch (earningsType) {
            case "dabbl":
                return <EarningsDabbl data={data} source={source}/>;
            case "research":
                return <EarningsDabbl data={data} source={source}/>;
            case "ad":
                return <EarningsAd data={data} source={source}/>;
            case "survey":
                return <EarningsSurvey data={data} source={source}/>;
            case "offer":
                return <EarningsSurvey data={data} source={source}/>;
            case "streak":
                return <EarningsStreak data={data} source={source} continueStreak={(streakDate, streakCount)=>{continueStreak(streakDate, streakCount)}}/>;
            case "promo":
                return <EarningsPromo data={data} source={source}/>;
            case "referral":
                return <EarningsReferral data={data} source={source}/>;
            case "receipt":
                return <EarningsReceipt data={data} source={source}/>;
            case "program":
                return <EarningsProgram data={data} source={source}/>;
            default:
                return <Col>Unknown {earningsType}</Col>
        }
    }

    function udpateSource(newSource){
        if (newSource === source) {
            return;
        }
        setIsLoading(true);
        setData(null);
        setSize(initialSize);
        setSource(newSource)
    }

    function updateSize(newSize){
        setIsLoading(true);
        setSize(newSize)
    }

    function onFilter(e){
        e.preventDefault()
        setFilter({from: fromDate, to: toDate, extra: extra})
    }

    function deleteDate(e){
        if (e.keyCode === 46 || e.keyCode === 8){
            e.target.value = ''
            if (e.target.name === 'fromDate'){
                setFromDate('')
            } else {
                setToDate('')
            }
            return
        }
    }

    return  <Container>
        <Row>
            <Col sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }}>
                <Card>
                    <CardBody>
                        <Row className='p-1'>
                            <h4>Earnings Type: {earningTitles[earningsType]}</h4>
                        </Row>
                        <Row>
                            <Col>
                            <Form>
                                {earningsType === 'survey' &&
                                <FormGroup row>
                                    <Label for="selectExtra" sm={2}>Vendor:</Label>
                                    <Col sm={4}>
                                    <Input type="select" name="selectExtra" id="selectExtra"
                                           onChange={(e) => setExtra(e.target.value)} defaultValue=''>
                                        <option></option>
                                        <option>inbrain</option>
                                        <option>pollfish</option>
                                        <option>tap</option>
                                        <option>theorem</option>
                                    </Input>
                                    </Col>
                                </FormGroup>
                                }
                                <FormGroup row>
                                    <Label for="fromDate" sm={2}>From:</Label>
                                    <Col sm={4}>
                                    <Input type="date" name="fromDate" defaultValue={''} max={moment().format('YYYY-MM-DD')} onKeyDown={(e) => deleteDate(e)}
                                           onChange={(e) => {setFromDate(moment.utc(e.target.value).format('YYYY-MM-DD'))}}/>
                                    </Col>
                                    <Label for="toDate" sm={2}>To:</Label>
                                    <Col sm={4}>
                                        <Input type="date" name="toDate" defaultValue={''} max={moment().format('YYYY-MM-DD')} onKeyDown={(e) => deleteDate(e)}
                                               onChange={(e) => {setToDate(moment.utc(e.target.value).format('YYYY-MM-DD'))}}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                </FormGroup>
                                <Button color="primary" className='float-right' onClick={(e) => onFilter(e)}>Apply</Button>
                            </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Nav tabs className='w-100'>
                                <NavItem>
                                    <NavLink active={source === 'esevent'}
                                        onClick={() => { udpateSource('esevent'); }}
                                    >
                                        From esevent
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        active={source === 'maindb'}
                                        disabled={earningsType === 'promo' || earningsType === 'referral'}
                                        onClick={() => { udpateSource('maindb'); }}
                                    >
                                        From maindb
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            </Col>
                        </Row>
                        <Fade in={data !== null} tag={Row}>
                            {data !== null && <Col>{ getEarningsTable() }</Col> }
                        </Fade>
                        {requestError && <Row><Col> <Alert color="danger"> {requestError}</Alert></Col></Row> }
                        {data !== null && !isLoading && (data.length === size) &&
                        <Row>
                            <Col>
                                <Button color="link" className='justify-content-center'
                                        onClick={() => updateSize(size + sizeIncrement)}>Load More...</Button>
                            </Col>
                        </Row>
                        }
                        {isLoading && <Row><Col className='p-2'><h5>Loading...</h5></Col></Row> }
                        <Row>
                            <Col>
                                <Button color="primary" className='float-right' onClick={()=>props.history.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
}

export default UserDetails;
