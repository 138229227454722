import React from "react";
import {Col} from "reactstrap";

function UserStatus(props) {
  const user = props.user;
  const labelSize = props.labelSize;

  return <React.Fragment>
    <Col className='font-weight-bold' sm={labelSize}>Status: </Col>
    { user.active && user.authActive
      ? <Col sm={12-labelSize} className='text-success'>Active</Col>
      : <Col sm={12-labelSize} className='text-danger'>Blocked: {user.name.indexOf('_del') !== -1 ? 'Soft Deleted' : user.status ? user.status : 'out of sync'}</Col>
    }
  </React.Fragment>
}

export default UserStatus;
