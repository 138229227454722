import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import * as d3 from "d3";
import moment from 'moment';

const margin = {top: 10, right: 0, bottom: 60, left: 30}

function ChartBalance(props) {

    const svgRef = useRef();
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(600)
    const [hoverIndex, setHoverIndex] = useState(null)
    const [tooltipXY, setTooltipXY] = useState({})

    useLayoutEffect( () => {
        if (width === svgRef.current.parentElement.offsetWidth) return;
        setWidth(svgRef.current.parentElement.offsetWidth)
        setHeight(svgRef.current.parentElement.offsetWidth / 16 * 9)
        }, [])

    var prevDayBalance =  props.balance;
    const data = props.data.map(b=>{
        const dayIncrement = (+b.credited||0) + (+b.creditedAd||0)  + (+b.creditedSurvey||0)  + (+b.creditedPromo||0) + (+b.creditedReferral||0) + (+b.creditedTransfer||0) - (+b.couponAwarded||0);
        prevDayBalance = prevDayBalance - dayIncrement
        return {date: moment.utc(b.createddate), value: prevDayBalance}
    });
    useEffect(() => {
        d3.select(svgRef.current).selectAll("*").remove();
        d3.json(data)
        const x = d3.scaleBand()
            .domain(data.map(d => d.date).reverse())
            .range([margin.left, width - margin.right])
            .padding(0.1)
        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.value)]).nice()
            .range([height - margin.bottom, margin.top])

        const xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(d3.timeFormat("%Y-%m-%d")))
            .call(g => g.selectAll(".domain").remove())
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", function(d) {
                return "rotate(-65)"
            });
        const yAxis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y).ticks(null, "s"))
            .call(g => g.selectAll(".domain").remove())

        const svg = d3.select(svgRef.current)
        svg.attr("viewBox", [0, 0, width, height])
            .append("g")
            .attr("fill", "steelblue")
            .selectAll("rect")
            .data(data)
            .join("rect")
            .attr("x", d => x(d.date))
            .attr("y", d => y(d.value))
            .attr("height", d => y(0) - y(d.value))
            .attr("width", x.bandwidth())
            .on('mouseenter', function (d, i) {
                setTooltipXY({x: d3.event.pageX, y: d3.event.pageY})
                setHoverIndex(i)
                setTimeout(()=> setHoverIndex(null), 4000)
            })
        svg.append("g").call(xAxis);
        svg.append("g").call(yAxis);
    }, [width, height]);

    return <div>
        <svg ref={svgRef} />
        {hoverIndex !== null && <div style={{left:tooltipXY.x+'px', top: (tooltipXY.y-window.scrollY)+'px', position: 'fixed', background: 'white', border:'1px solid black', borderRadius:'8px', pointerEvents:'none', fontSize:'0.75rem', padding:'4px'}}>
            {data[hoverIndex].date.format('YYYY-MM-DD')}<br/>
            Value: {(+data[hoverIndex].value || 0).toFixed(3)}<br/>
        </div> }
    </div>
}

export default ChartBalance;