import React, {useState} from 'react';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Collapse,
    Fade,
    Form, FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import axios from "axios";
import {useUser} from "../context/user"
import UserStatus from "./UserStatus";
import moment from "moment";
const usernameRegex = /^(([0-9]{11})|([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}))$/;
const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const labelSize = 4;

function LinkReferral(props) {
    const {dabblUser } = useUser();
    const [username, setUsername] = useState("");
    const [isValidUsername, setIsValidUsername] = useState(false);
    const [referralUser, setReferralUser] = useState('');
    const [rewardedStatus, setRewardedStatus] = useState('');
    const [comment, setComment] = useState('');
    const [requesting, setRequesting] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [requestError, setRequestError] = useState('');

    function requestUserRewardStatus(referralId) {
        axios.get(`/user/${dabblUser.id}/referral/${referralId}`)
            .then(result => {
                setRequesting(false);
                if (result && result.status === 200 && !result.data.error) {
                    console.log(result.data)
                    setRewardedStatus(result.data);
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequesting(false);
            setRequestError(e.message);
        });
    }

    function getReferralAction() {
        if (!referralUser) {
            return 'Specify the user...'
        }
        if (referralUser.id === dabblUser.id ) {
            return 'Can\'t link user to itself'
        }
        if (referralUser.referraluserid === null){
            if (moment.utc(referralUser.createddate) < moment.utc(dabblUser.createddate)) {
                return 'Registration date mismatch'
            }
            return 'Link Users'
        }
        if (referralUser.referraluserid !== dabblUser.id ) {
            return 'User id already linked with another user'
        }
        if (referralUser.lifetimeuse < 1) {
            return 'Lifetime less than $1'
        }
        return rewardedStatus && rewardedStatus.length === 0 ? 'Reward User' : 'Already linked and rewarded'
    }

    function isValidReferralAction() {
        return referralUser && username === referralUser.name && successMessage === null && referralUser.id !== dabblUser.id &&
            (referralUser.referraluserid === null && moment.utc(referralUser.createddate) > moment.utc(dabblUser.createddate) ||
                referralUser.lifetimeuse >= 1 && referralUser.referraluserid === dabblUser.id && rewardedStatus && rewardedStatus.length === 0 )
    }

    function requestReferralUser(){
        setRequestError(null);
        axios.get(`/user/${username}`)
            .then(result => {
                setRequesting(false);
                if (result && result.status === 200 && !result.data.error) {
                    setReferralUser(result.data)
                    requestUserRewardStatus(result.data.id);
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }

    function getReferralUser(e, username) {
        e.preventDefault();
        requestReferralUser()
    }

    function linkReferral() {
        axios.post(`/user/${dabblUser.id}/linkreferral`, {id: referralUser.id, comment: comment})
            .then(result => {
                setRequesting(false);
                if (result && result.status === 200) {
                    setSuccessMessage('Successfully linked');
                    setTimeout(() => { setSuccessMessage(null)}, 3000);
                    setTimeout(() => { requestReferralUser()}, 500);
                } else {
                    setRequestError(result.data);
                }
            }).catch(e => {
            setRequesting(false);
            setRequestError(e.message);
        });
    }

    function rewardReferral() {
        axios.post(`/user/${dabblUser.id}/rewardreferral`, {id: referralUser.id, comment: comment})
            .then(result => {
                if (result && result.status === 200) {
                    setSuccessMessage('Successfully rewarded');
                    setTimeout(() => { setSuccessMessage(null)}, 3000);
                    setTimeout(() => { requestUserRewardStatus(referralUser.id)}, 1000);
                    setComment('');
                } else {
                    setRequesting(false);
                    setRequestError(result.data);
                }
            }).catch(e => {
            setRequesting(false);
            setRequestError(e.message);
        });
    }


    function onSubmitLinkReferral(e) {
        e.preventDefault();
        setSuccessMessage(null);
        setRequestError(null);
        setRequesting(true);
        if (referralUser.referraluserid !== dabblUser.id) {
            linkReferral()
        } else {
            rewardReferral()
        }
    }

    return<React.Fragment>
        <h4>Link Referrals:</h4>
        <Card>
            <CardBody>
                <Row>
                    <Col className='font-weight-bold' sm={3}>User:</Col>
                    <Col sm={6}><FormGroup>
                        <Input
                            type="text"
                            name="username2"
                            id="username2"
                            placeholder="Enter Phone Number, email or User Id"
                            value={ username }
                            valid={ isValidUsername }
                            onChange={ (e) => {
                                setIsValidUsername(usernameRegex.test(e.target.value) || emailRex.test(e.target.value));
                                setUsername(e.target.value)
                            } }
                        />
                        <FormFeedback valid>{`Valid ${ emailRex.test(username) ? 'email' : username.length === 11 ? 'Phone Number' : 'User Id'}`}</FormFeedback>
                    </FormGroup></Col>
                    <Col sm={3}> <Button color='primary' onClick={(e)=>getReferralUser(e, username)} disabled={!isValidUsername}>Get Info</Button></Col>
                </Row>
                <Fade in={referralUser !== null} tag={Row}>
                    {referralUser && referralUser.id && referralUser.name === username &&
                    <Col>
                        <Row>
                            <Col className='font-weight-bold' sm={labelSize}>Phone: </Col>
                            <Col sm={12-labelSize}> {referralUser.name} </Col>
                        </Row>
                        <Row>
                            <Col className='font-weight-bold' sm={labelSize}>UserId: </Col>
                            <Col sm={12-labelSize}> {referralUser.id} </Col>
                        </Row>
                        <Row>
                            <UserStatus user={referralUser} labelSize={labelSize}/>
                        </Row>
                        <Row>
                            <Col className='font-weight-bold' sm={labelSize}>Created: </Col>
                            <Col sm={12-labelSize}> {referralUser.createddate != null ? moment.utc(referralUser.createddate).format('YYYY-MM-DD HH:mm') : '-' } </Col>
                        </Row>
                        <Row>
                            <Col className='font-weight-bold' sm={labelSize}>Referred From: </Col>
                            <Col sm={12-labelSize}> {referralUser.referredfrom || 'None' } </Col>
                        </Row>
                        <Row>
                            <Col className='font-weight-bold' sm={labelSize}>Lifetime Earnings: </Col>
                            <Col sm={12-labelSize}> {referralUser.lifetimeuse } </Col>
                        </Row>
                        <Row>
                            <Col className='font-weight-bold' sm={labelSize}>Credited: </Col>
                            <Col sm={12-labelSize}> { rewardedStatus === null ? 'Loading...' : `${rewardedStatus.length > 0 ? 'Yes' : 'No'}` } </Col>
                        </Row>
                    </Col>
                    }
                </Fade>
                {referralUser && !referralUser.id && <Alert color="warning" className='mt-3'> User Not Found... </Alert>}
                {referralUser && referralUser.name === username &&
                <Row>
                    <Col>
                        <Row>
                            <Col className='font-weight-bold' sm={3}>Comment:</Col>
                            <Col sm={6}><FormGroup>
                                <Input
                                    type="text"
                                    name="comment"
                                    id="comment_referral"
                                    placeholder='Comment or ticket number'
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e.target.value)
                                    }}
                                />
                                <FormFeedback
                                    valid>{`Valid ${emailRex.test(username) ? 'email' : username.length === 11 ? 'Phone Number' : 'User Id'}`}</FormFeedback>
                            </FormGroup></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color='primary' className='w-100'
                                        disabled={requesting || !isValidReferralAction()}
                                        onClick={(e) => onSubmitLinkReferral(e)}>
                                    {requesting ? 'Processing...' : getReferralAction()}
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col fluid="true">
                                {requestError && <Col><Alert color="danger"> {requestError} </Alert></Col>}
                                {successMessage && <Col> <Alert color="success" sm={4}> {successMessage} </Alert> </Col>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                }
            </CardBody>
        </Card>
    </React.Fragment>
}

export default LinkReferral;
