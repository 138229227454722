import React from "react";
import {Table, tbody, th, thead, tr} from "reactstrap";

function EarningsTable(props) {
    const data = props.data;
    console.log(data)
    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>Date</th>
            <th>Dabbl</th>
            <th>Ad</th>
            <th>Survey</th>
            <th>Offer</th>
            <th>Streak</th>
            <th>Promo</th>
            <th>Referral</th>
            <th>Receipt</th>
            <th>Coupons</th>
            <th>Transfer</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr key={index}>
                    <th scope="row">{row.createddate}</th>
                    <td>{row.credited}</td>
                    <td>{row.creditedAd}</td>
                    <td>{row.creditedSurvey}</td>
                    <td>{row.creditedWebOffer}</td>
                    <td>{row.creditedStreak}</td>
                    <td>{row.creditedPromo}</td>
                    <td>{row.creditedReferral}</td>
                    <td>{row.creditedReceipt}</td>
                    <td>{row.couponAwarded}</td>
                    <td>{row.creditedTransfer}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default EarningsTable;