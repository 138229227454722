import React, {useState} from 'react';
import {Alert, Button, Card, CardBody, Col, Collapse, Form, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import axios from "axios";
import {useUser} from "../context/user"
const regexAmount  = /^\d+(\.\d{1,3})?$/;

function AddEarnings(props) {
    const onAmountAdded = props.onAmountAdded;
    const verifiedPurchases = props.verifiedPurchases || [];
    const verifiedPurchasesDict = verifiedPurchases.reduce((a,x) => ({...a, [x.id]: x}), {})
    const retailers = ['7-Eleven','ALDI','Amazon','BP','Burger King','CVS','Chick-fil-A','Circle K','Costco Wholesale Corp.','Dollar General','Dollar Tree','Dunkin\' Donuts','Family Dollar','Food Lion','H-E-B','Home Depot','Kroger','Lowe\'s Home Improvement','Marshalls','McDonald\'s','Meijer','Publix Super Markets','Rite Aid','Ross Dress for Less','SUBWAY','Safeway','Sam\'s Club','Shell','ShopRite','Stop & Shop','T.J. Maxx','Taco Bell','Target','Walgreens','Walmart','Wendy\'s','Albertsons ','BJ\'s Wholesale','Cub Foods','Giant Eagle','Rite Aid','Trader Joe\'s','Wegman\'s','Whole Foods Marke'].sort()

    const [needConfirmation, setNeedConfirmation] = useState(false);
    const [requestAddEarningsError, setRequestAddEarningsError] = useState('');
    const {dabblUser, setDabblUser } = useUser();


    const [amount, setAmount] = useState(null);
    const [source, setSource] = useState('');
    const [extra, setExtra] = useState('');
    const [comment, setComment] = useState('');
    const [requesting, setRequesting] = useState(false);
    const [selectedCreditType, setSelectedCreditType] = useState('');
    const [success, setSuccess] = useState(false);
    const [receiptData, setReceiptData] = useState({rsgid:'', qty:0, amount: 0.00});

    function getExtraTitle(){
        switch (source) {
            case 'survey', 'offer': return 'Vendor';
            case 'promo': return 'Promotion';
            case 'referral': return 'User Id';
            default: return ''
        }
    }

    function isValidAmount(amount){
        if (amount === null) return null;
        const amountNumber = +amount;
        return regexAmount.test(amount) && !isNaN(amountNumber) && amountNumber <= 25.0 && amountNumber >= 0
    }

    function isValidSelection() {
        if (source === '') return false;
        if (source === 'survey' && extra === '') return false;
        if (source === 'offer' && extra === '') return false;
        if (source === 'promo' && extra === '') return false;
        if (source === 'receipt' && (receiptData.rsgid === '' || receiptData.retailer === '' || !(selectedCreditType === 'COUNT_THRESHOLD' ? receiptData.qty : receiptData.amount))) return false;
        return true;
    }

    function onSubmitAddEarnings(e) {
        e.preventDefault();
        setSuccess(false);
        setRequestAddEarningsError(null);
        setRequesting(true);
        if ((+amount) > 2.5  &&  !needConfirmation){
            setNeedConfirmation(true)
            setTimeout(() => { setRequesting(false)}, 500);
            return
        }
        setNeedConfirmation(false)
        let data  = {amount: amount, source:source, extra: extra, comment: comment}
        if (source === 'receipt') {
            data = {...data, data: receiptData}
        }
        axios.post(`/user/${dabblUser.id}/add`, data)
            .then(result => {
                setRequesting(false);
                if (result && result.status === 200) {
                    setSuccess(true);
                    setTimeout(() => { setSuccess(false)}, 3000);
                    setAmount(null);
                    setSource('');
                    setExtra('');
                    setComment('');
                    setSelectedCreditType('');
                    setReceiptData({});
                    onAmountAdded();
                } else {
                    setRequestAddEarningsError(result.data);
                }
            }).catch(e => {
            setRequesting(false);
            setRequestAddEarningsError(e.message);
        });
    }

    function addDataToReceiptData(data){
        setReceiptData({...receiptData, ...data})
    }

    return<React.Fragment>
        <h4>Add Earning:</h4>
        <Card>
        <CardBody>
            <Row>
                <Form onSubmit={onSubmitAddEarnings} style={{width:'100%'}}>
                    <FormGroup row>
                        <Label sm={2}>Amount</Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                name="amount"
                                id="amount"
                                placeholder="Enter amount in format XX.XXX"
                                step='0.001'
                                value={ amount || '' }
                                valid={ isValidAmount(amount) }
                                invalid={ amount !== null && !isValidAmount(amount) }
                                onChange={ (e) => {
                                    setAmount(e.target.value)
                                    setNeedConfirmation(false)
                                } }
                            />
                            <FormText>Amount should be between 0.001 and 25.00</FormText>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Source</Label>
                        <Col sm={10}>
                            <Input type="select" name="source" id="source"
                                   value={ source }
                                   onChange={ (e) => {
                                       setSource(e.target.value)
                                       if (e.target.value !== source){
                                           setExtra('')
                                       }
                                   } }>
                                <option value=''>-- Select One --</option>
                                <option value='experience'>Dabbl</option>
                                <option value='ad'>Ad</option>
                                <option value='survey'>Survey</option>
                                <option value='offer'>Offer</option>
                                <option value='receipt'>Receipt</option>
                                <option value='promo'>Bonus</option>
                                <option value='registration'>Registration</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    {source === 'receipt' &&
                    <Col>
                            <FormGroup row>
                                <Label sm={3}>Verified Purchase</Label>
                                <Col sm={9}>
                                    <Input type="select" name="vefiriedPurchase" id="vefiriedPurchase"
                                           value={ receiptData.rsgid }
                                           onChange={(e) => {
                                               if (!e.target.value) {
                                                   setReceiptData({})
                                                   return
                                               }
                                               setSelectedCreditType(verifiedPurchasesDict[e.target.value].credittype);
                                               let data = {rsgid: e.target.value}
                                               // if (verifiedPurchasesDict[e.target.value].credittype === 'COUNT_THRESHOLD'){
                                               //     data.value = parseInt(data.value || '1')
                                               // }
                                               addDataToReceiptData(data)
                                           }}>
                                        <option value=''>-- Select One --</option>
                                        {verifiedPurchases.map((v) => (
                                            <option key={v.id}
                                                    value={v.id}>{v.productdescription}</option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                            { receiptData.rsgid &&
                            <FormGroup row>
                                <Label sm={3}>Retailer</Label>
                                <Col sm={9}>
                                    <Input type="select" name="retailer" id="retailer"
                                           value={ receiptData.retailer }
                                           onChange={(e) => {
                                               addDataToReceiptData({retailer: e.target.value})
                                           }}>
                                        <option value=''>-- Select One --</option>
                                        {retailers.map((r) => (
                                            <option key={r} value={r}>{r}</option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                            }

                        {receiptData.rsgid &&
                        <FormGroup row>
                            <Label sm={3}>{selectedCreditType === 'COUNT_THRESHOLD' ? "Items Count" : "Dollar Amount"}</Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="value"
                                    id="value"
                                    placeholder={`Enter amount in format ${selectedCreditType === 'COUNT_THRESHOLD' ? 'XX' : 'XX.XX'}`}
                                    step={selectedCreditType === 'COUNT_THRESHOLD' ? '1': '0.01'}
                                    value={selectedCreditType === 'COUNT_THRESHOLD' ? receiptData.qty: receiptData.amount}
                                    valid={isValidAmount(selectedCreditType === 'COUNT_THRESHOLD' ? receiptData.qty: receiptData.amount)}
                                    invalid={!isValidAmount(selectedCreditType === 'COUNT_THRESHOLD' ? receiptData.qty: receiptData.amount)}
                                    onChange={(e) => {
                                        addDataToReceiptData( selectedCreditType === 'COUNT_THRESHOLD' ? {qty: e.target.value, amount:0.000} : {amount: e.target.value, qty:0})
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        }
                    </Col>
                    }
                    {['survey', 'promo', 'offer'].indexOf(source) !== -1 &&
                    <FormGroup row>
                        <Label sm={2}>{getExtraTitle()}</Label>
                        <Col sm={10}>
                            {source === 'survey' ?
                                <Input type="select" name="extra" id="extra"
                                       value={ extra }
                                       onChange={(e) => {
                                           setExtra(e.target.value)
                                       }}>
                                    <option value=''>-- Select One --</option>
                                    <option value='tap'>Tap Research</option>
                                    <option value='pollfish'>Pollfish</option>
                                    <option value='inbrain'>InBrain</option>
                                    <option value='theorem'>Theorem Reach</option>
                                    <option value='peanut'>Peanut Labs</option>
                                    <option value='unknown'>Unknown</option>
                                </Input>
                                : source === 'offer' ?
                                    <Input type="select" name="extra" id="extra"
                                           value={ extra }
                                           onChange={(e) => {
                                               setExtra(e.target.value)
                                           }}>
                                        <option value=''>-- Select One --</option>
                                        <option value='tapjoy'>TapJoy</option>
                                        <option value='ironsource'>IronSource</option>
                                        <option value='unknown'>Unknown</option>
                                    </Input>
                                    :
                                    <Input
                                        type="text"
                                        name="extra"
                                        id="extra"
                                        value={extra}
                                        onChange={(e) => {
                                            setExtra(e.target.value)
                                        }}
                                    />
                            }
                        </Col>
                    </FormGroup>
                    }
                    <FormGroup row>
                        <Label sm={2}>Comment:</Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="comment"
                                id="comment"
                                placeholder='Comment or ticket number'
                                value={ comment }
                                onChange={ (e) => {
                                    setComment(e.target.value)
                                } }
                            />
                        </Col>
                    </FormGroup>
                    <Row>
                        <Col fluid="true">
                            {requestAddEarningsError && <Col><Alert color="danger"> {requestAddEarningsError} </Alert></Col>}
                            {success && <Col> <Alert color="success" sm={4}> User has been updated. </Alert> </Col> }
                        </Col>
                        <Col xs="3">
                            <Button color={needConfirmation ? 'danger' : 'primary'} className='w-100' disabled={requesting || !isValidAmount(amount) || !isValidSelection() }>{needConfirmation ? 'Confirm' : requesting ? 'Processing...' : 'Add Earnings'}</Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </CardBody>
    </Card>
    </React.Fragment>
}

export default AddEarnings;
