import React from "react";
import {Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function DevicesTable(props) {
    console.log('1231')
    console.log(props)
    const data = props.data;
    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>Date</th>
            <th>Last Mod Date</th>
            <th>OS</th>
            <th>Device</th>
            <th>App</th>
            <th>Device Id</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr key={index}>
                    <th scope="row">{moment.utc(row.createddate).format('YYYY-MM-DD')}</th>
                    <td>{moment.utc(row.lastmodifieddate).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{`${row.devicetype} ${row.osversion}`}</td>
                    <td>{row.devicemodel}</td>
                    <td>{`v${row.appversion}`}</td>
                    <td>{row.deviceid}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default DevicesTable;