import React from "react";
import {Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function SupportHistoryByUser(props) {
    const data = props.data;

    return <React.Fragment>
        <h4>History:</h4>
    <Table size="sm" bordered hover responsive style={{fontSize:'0.75rem'}}>
        <thead>
        <tr>
            <th>User</th>
            <th>Date</th>
            <th>Action</th>
            <th>Data</th>
            <th>Success</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr key={index}>
                    <td>{row.email ? row.email.substring(0, row.email.lastIndexOf("@")) : ''}</td>
                    <td style={{whiteSpace:'nowrap'}}>{moment.utc(row.createddate).format('YYYY-MM-DD HH:mm')}</td>
                    <td style={{whiteSpace:'nowrap'}}>{row.action}</td>
                    <td style={{wordBreak:'break-all'}}>{row.info ? JSON.stringify(row.info) : ''}</td>
                    <td style={{whiteSpace:'nowrap'}}>{row.success ? 'true' : 'false'}</td>
                </tr>
            })}
        </tbody>
    </Table>
    </React.Fragment>
}

export default SupportHistoryByUser;