import React from "react";
import {Table, thead, tr, th, tbody} from "reactstrap"
import moment from "moment";

function EarningsProgram(props) {
    const data = props.data;
    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>#</th>
            <th>Date</th>
            <th>Program Name</th>
            <th>Amount Earned</th>
            <th>Status</th>
            <th>Card Ordered</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => {
            return <tr>
                <th scope="row">{index + 1}</th>
                <td>{moment.utc(row.transactiondate).format('YYYY-MM-DD HH:mm')}</td>
                <td>{row.programname }</td>
                <td>{row.useramount && row.useramount !== '' ? `$${row.useramount}` : '-'}</td>
                <td>{row.status }</td>
                <td>{row.orderid ? 'Yes' : '-' }</td>
            </tr>
        })}
        </tbody>
    </Table>
}

export default EarningsProgram;
