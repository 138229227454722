import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
    const { authUser } = useAuth();

    return (
        <Route
            {...rest}
            render={props => authUser ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/login", state: { referer: props.location.pathname !== '/login' ? props.location : '/'} }}   />
                )
            }
        />
    );
}

export default PrivateRoute;