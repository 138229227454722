import React, {useState} from 'react';
import {Link} from "react-router-dom";
import moment from 'moment';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Fade,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row, CustomInput
} from "reactstrap";
import classnames from 'classnames';
import axios from "axios";
import {useUser} from "../context/user"
import {appUserStatuses, earningTitles} from "../config";
import LookupAdjustStatus from "../components/LookupAdjustStatus";
import UserStatus from "../components/UserStatus";
import AddEarnings from "../components/AddEarnings";
import LinkReferral from "../components/LinkReferral";
import GiftCards from "../components/GiftCards";
import SupportHistoryByUser from "../components/SupportHistoryByUser";
import {useIvt} from "../context/ivt";
import IvtTable from "../components/IvtTable";
import DevicesTable from "../components/DevicesTable";
import Retailers from "../components/Retailers";
import VerifiedPurchases from "../components/VerifiedPurchases";

const usernameRegex = /^([0-9]{11})$/;
const userIdRegex = /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;
const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const labelSize = 4;

const userActions = {
    EARNING: {tabId: 'earning', title: 'Add Earnings'},
    IVT: {tabId: 'ivt', title: 'IVT'},
    HISTORY: {tabId: 'history', title: 'History'},
    REFERRAL: {tabId: 'referral', title: 'Link Referral'},
    RETAILERS: {tabId: 'retailers', title: 'Account Links'},
    VERIFIED_PURCHASES: {tabId: 'verified_purchases', title: 'Verified Purchases'},
    CARDORDER: {tabId: 'cardorder', title: 'Gift Cards'},
    DEVICES: {tabId: 'devices', title: 'Devices'},
    MISCELLANEOUS: {tabId: 'misc', title: 'Miscellaneous'},
}

function UserLookup(props) {
    const [username, setUsername] = useState("");
    const [requestError, setRequestError] = useState('');
    const [isValidUsername, setIsValidUsername] = useState(false);
    const [user, setUser] = useState(null);
    const [customerdata, setCustomerdata] = useState(null);
    const [totalGiftCardAmount, setTotalGiftCardAmount] = useState(null);
    const [userearnings, setUserEarnigns] = useState(null);
    const [userHistoryData, setUserHistoryData] = useState(null);
    const [isDateOfBirthEditing, setIsDateOfBirthEditing] = useState(false);
    const [isStatusEditing, setIsStatusEditing] = useState(false);
    const [ivtComment, setIvtComment] = useState('');
    const [status, setStatus] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [isUsernameEditing, setIsUsernameEditing] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [userUpdateMessage, setUserUpdateMessage] = useState('');
    const [isDeleteProcessing, setIsDeleteProcessing] = useState(false);
    const [deleteResultMessage, setDeleteResultMessage] = useState('');
    const {dabblUser, setDabblUser } = useUser();
    const [verifiedPurchases, setVerifiedPurchases] = useState(null);
    const [ivtData, setIvtData] = useState(null);
    const [userDevices, setUserDevices] = useState(null);
    const [actionToShow, setActionToShow] = useState(userActions.HISTORY);
    if (!user && dabblUser) {
        setUsername(dabblUser.name);
        setUser(dabblUser);
        requestUserDetails(dabblUser.id);
        requestCustomerData(dabblUser.id);
        requestTotalGiftCardAmount(dabblUser.id);
        requestUserHistoryData(dabblUser.id);
        requestUserIvt(dabblUser.name)
        requestUserDevices(dabblUser.id)
        requestVerifiedPurchases(dabblUser.id)
    }


    function onSubmit(e, username) {
        console.log(username)
        e && e.preventDefault();
        setUser(null);
        setDabblUser(null);
        setUserEarnigns(null);
        setRequestError(null);
        setIvtData(null)
        setUserDevices(null)
        axios.get(`/user/${username}`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setUser(result.data);
                    setDabblUser(result.data);
                    requestUserDetails(result.data.id);
                    requestCustomerData(result.data.id);
                    requestTotalGiftCardAmount(result.data.id);
                    requestUserHistoryData(result.data.id)
                    requestUserIvt(result.data.name)
                    requestUserDevices(result.data.id)
                    requestVerifiedPurchases(result.data.id)
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }

    function userRefresh(userid) {
      console.log('Calling userRefresh for ', userid);
      
      axios.get(`/user/${userid}`)
        .then(result => {
            if (result && result.status === 200 && !result.data.error) {
                setUser(result.data);
                setDabblUser(result.data);
                requestUserHistoryData(result.data.id)
            } else {
                setRequestError('Error occurred on backend side.');
            }
        })
        .catch(e => {
        setRequestError(e.message);
        });
    }

    function requestUserDetails(userid){
        if (!userid) { return }
        axios.get(`/user/${userid}/earnings`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setUserEarnigns(result.data)
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }

    function requestCustomerData(userid){
        if (!userid) { return }
        axios.get(`/user/${userid}/customerdata`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setCustomerdata(result.data);
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }

    function requestTotalGiftCardAmount(userid){
        if (!userid) { return }
        axios.get(`/user/${userid}/totalgiftcardamount`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setTotalGiftCardAmount(result.data.giftcardtotalamount || 0);
                }
            }).catch(e => {
                console.error(e.message)
        });
    }

    function requestUserHistoryData(userid){
        if (!userid) { return }
        axios.get(`/user/support/${userid}/allhistory`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setUserHistoryData(result.data)
                }
            }).catch(e => {  });
    }

    function requestUserIvt(phonenumber){
        if (!phonenumber) { return }
        axios.get(`/ivt/ivthitlog?phonenumber=${phonenumber}`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setIvtData(result.data)
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }

    function requestUserDevices(userid){
        if (!userid) { return }
        axios.get(`/user/${userid}/devices`)
            .then(result => {
                if (result && result.status === 200 && !result.data.error) {
                    setUserDevices(result.data || 0);
                }
            }).catch(e => {
            console.error(e.message)
        });
    }

    function requestVerifiedPurchases(userid){
        if (!userid) { return }
        axios.get(`/user/${userid}/verifiedpurchases`)
            .then(result => {
                console.error(result)
                console.error(result.data)
                if (result && result.status === 200 && !result.data.error) {
                    setVerifiedPurchases(result.data || 0);
                }
            }).catch(e => {
            console.error(`Error getting verified purchase: ${(e && e.message) ? e.message : "unknown"}`)
        });
    }

    // function fixDabblResearchIssue(){
    //     setFixDabblProcessing(true)
    //     axios.post(`/user/fixdabblresearchissue`)
    //         .then(result => {
    //             console.log(result)
    //             if (result && result.status === 200) {
    //                 setFixDabblMessage('Success: ' + result.data)
    //             } else {
    //                 setFixDabblMessage('Error')
    //             }
    //             setFixDabblProcessing(false)
    //         }).catch(e => {
    //         setFixDabblProcessing(false)
    //         setFixDabblMessage('Error: ' + e.message)
    //         console.error(e.message)
    //     });
    // }

    function deleteCurrentUser(event, name){
        setIsDeleteProcessing(true)
        axios.delete(`/user/name/${name}`)
            .then(result => {
                console.log(result)
                if (result && result.status === 200) {
                    setDeleteResultMessage('Success: ' + result.data)
                } else {
                    setDeleteResultMessage('Error')
                }
                setIsDeleteProcessing(false)
            }).catch(e => {
            setIsDeleteProcessing(false)
            setDeleteResultMessage('Error: ' + e.message)
            console.error(e.message)
        });
    }

    function updateCurrentUser(e, data){
        e.preventDefault();
        // return
        setRequestError('')
        axios.post(`/user/${dabblUser.id}`, data)
            .then(result => {
                if (result && result.status === 200) {
                    setUserUpdateMessage('User updated');
                    setTimeout(() => { setUserUpdateMessage('')}, 1000);
                    if (data.username){
                        setUsername(data.username)
                        onSubmit(null, data.username)
                        return
                    }
                    setUser({...user, ...data})
                    userRefresh(data.username ? data.username : dabblUser.id)
                } else {
                    setRequestError('failed to update user');
                }
            }).catch(e => {
                console.log(e)
                setRequestError(`Error: ${e && e.data ? e.data.error: e}`);
        });
    }

    function clearCoupon(e){
        e.preventDefault();
        // return
        setRequestError('')
        axios.post(`/user/${dabblUser.id}/clearcoupon`)
            .then(result => {
                if (result && result.status === 200) {
                    setUserUpdateMessage('Coupon cleared');
                    setTimeout(() => { setUserUpdateMessage('')}, 1000);
                    requestCustomerData(dabblUser.id)
                } else {
                    setRequestError('failed to clear coupon');
                }
            }).catch(e => {
            console.log(e)
            setRequestError(`Error: ${e && e.data ? e.data.error: e}`);
        });
    }

    function ivtWhitelist(ip, userid){
        setRequestError('')
        axios.post(`/ivt/whitelist`, {...(ip && {ip: ip}), ...(userid && {userid: userid}), comment: ivtComment})
            .then(result => {
                if (result && result.status === 200) {
                    requestUserIvt(user.name)
                    setIvtComment('')
                } else {
                    setRequestError('failed to whitelist');
                }
            }).catch(e => {
            console.log(e)
            setRequestError(`Error: ${e && e.data ? e.data.error: e}`);
        });
    }

    function editDateOfBirth(e){
        e.preventDefault();
        setIsDateOfBirthEditing(true)
    }

    function onAmountAdded(){
        requestUserDetails(dabblUser.id);
        requestCustomerData(dabblUser.id);
        requestTotalGiftCardAmount(dabblUser.id);
        requestUserHistoryData(dabblUser.id)
    }

    function getEarnignsHeaders(){
        return (
            <Row>
                <Col sm={3}> </Col>
                <Col sm={3}>esevent</Col>
                <Col sm={3}>maindb</Col>
                <Col sm={3}> </Col>
            </Row>
        )
    }

    function getEarnignsRow(earning){
        if (!earning){
            return
        }
        return <Row className={earning.earningstype === 'total' ? 'font-weight-bold' : ''} key={earning.earningtype}>
                <Col sm={3}> {earningTitles[earning.earningtype]} </Col>
                <Col sm={3}> ${(+earning.esevent).toFixed(3)} </Col>
                <Col sm={3}> {earning.maindb ? `$${(+earning.maindb).toFixed(3)}` : 'none' }  </Col>
                <Col sm={3}>{
                    earning.earningtype === 'register' ? <div/> :
                    earning.earningtype !== 'total' ?
                    <Link to={{pathname: '/userdetails', state: {earningsType: earning.earningtype}}}>view all</Link>
                    :
                    <Link to={{pathname: '/usercharts', state: {balance: customerdata !== null ? customerdata.couponbufferbalance : 0}}}>{customerdata !== null ? 'Charts' : ''}</Link>
                }
                </Col>
            </Row>
    }

    return (
        <Container>
            <Row>
                <Col sm={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Form onSubmit={(e) => onSubmit(e, username)} style={{width:'100%'}}>
                                    <Col>
                                        <FormGroup>
                                            <Label>Phone number, loyaltyId, User Id</Label>
                                            <Input
                                                type="text"
                                                name="username"
                                                id="username"
                                                placeholder="Enter Phone Number, email, Loyalty Id or User Id"
                                                value={ username }
                                                valid={ isValidUsername }
                                                onChange={ (e) => {
                                                    setIsValidUsername(usernameRegex.test(e.target.value) || userIdRegex.test(e.target.value) || emailRex.test(e.target.value));
                                                    setUsername(e.target.value)
                                                } }
                                            />
                                            <FormFeedback valid>{`Valid ${ emailRex.test(username) ? 'email' : username.length === 11 ? 'Phone Number or Loyalty Id' : 'User Id'}`}</FormFeedback>
                                            <FormText>
                                                <span>Enter any of the following:</span>
                                                <ul>
                                                    <li>Phone Number or Loyalty Id like 19998882222 or 412444222333</li>
                                                    <li>User id like 7d34207f-a0fe-4b41-83ac-9be7ab8b3d00</li>
                                                    <li>Email address</li>
                                                </ul>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    {requestError &&
                                    <Col>
                                        <Alert color="danger">
                                            {requestError}
                                        </Alert>
                                    </Col>
                                    }
                                    <Button color="primary" className='float-right' disabled={!usernameRegex.test(username) && !userIdRegex.test(username) && !emailRex.test(username)}>Submit</Button>
                                </Form>
                            </Row>
                            <Fade in={user !== null} tag={Row}>
                                {user && user.id &&
                                    <Col>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>{ user.name.length === 36 ? "LoyaltyId:" : "Phone:" }</Col>
                                            <Col sm={12-labelSize}>
                                                { isUsernameEditing ?
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Input type="text"
                                                                   name="newusername"
                                                                   id="newusername"
                                                                   placeholder="Enter Phone Number"
                                                                   defaultValue=''
                                                                   onChange={ (e) => {setNewUsername(e.target.value)} }/>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Button color="primary"  onClick={(e) => {setIsUsernameEditing(false);if(usernameRegex.test(newUsername)) {updateCurrentUser(e, {username: newUsername})}}}>Update</Button>&nbsp;
                                                            <Button color="secondary" onClick={(e) => {setIsUsernameEditing(false);e.preventDefault();}}>Cancel</Button>
                                                        </Col>
                                                    </Row> :
                                                    <span>{ user.name.length === 36 ? user.loyaltyid : user.name}  &nbsp;
                                                        { user.name.length !== 36 &&
                                                            <Button color="link" className='p-0 align-baseline'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setIsUsernameEditing(true)
                                                                }}>edit</Button>
                                                        }
                                                    </span>
                                                    }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>UserId: </Col>
                                            <Col sm={12-labelSize}> {user.id} </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Status:</Col>
                                            <Col sm={12-labelSize}>
                                                    <Row>
                                                        <Col sm='auto'>
                                                        { user.active && user.authActive
                                                            ? <span className='text-success'>Active</span>
                                                            : <span className='text-danger'>Blocked: {user.name.indexOf('_del') !== -1 ? 'Soft Deleted' : user.status ? user.status : 'out of sync'}</span>
                                                        }
                                                        </Col>
                                                        {isStatusEditing ?
                                                            <Col sm={12}>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <Row>
                                                                        <CustomInput type="select" name="selectStatus"
                                                                                     id='selectStatus'
                                                                                     defaultValue={user.status}
                                                                                     onChange={(e) => {setStatus(e.target.value); console.log('status change to ' + e.target.value)}}>
                                                                            {Object.keys(appUserStatuses).map((k) => (
                                                                                <option key={k}
                                                                                        value={appUserStatuses[k]}>{appUserStatuses[k]}</option>
                                                                            ))}
                                                                        </CustomInput>
                                                                    {/*&nbsp;*/}
                                                                    {/*<Input type="text" name="comment" id="comment"*/}
                                                                    {/*    placeholder='Comment or ticket number'*/}
                                                                    {/*    value={ statusComment }*/}
                                                                    {/*    onChange={ (e) => {*/}
                                                                    {/*        setStatusComment(e.target.value)*/}
                                                                    {/*    } }*/}
                                                                    {/*/>*/}
                                                                    </Row>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Button color="primary"  disabled={status === user.status}
                                                                            onClick={(e) => {setIsStatusEditing(false);updateCurrentUser(e, {status: status || null})}}>
                                                                        {!user.active && status === '' ? 'Unblock' : user.active && status !== null ? 'Block' : 'Update' }
                                                                    </Button>&nbsp;
                                                                    <Button color="secondary" onClick={(e) => {e.preventDefault();setIsStatusEditing(false);}}>Cancel</Button>
                                                                </Col>
                                                            </Row>
                                                            </Col> :
                                                            <Col sm={5}>
                                                            <Button color="link" className='p-0 align-baseline' onClick={(e) => {e.preventDefault();setIsStatusEditing(true)}}>change</Button>&nbsp;
                                                            {!user.active && <Button color="link" className='p-0 align-baseline' onClick={(e) => updateCurrentUser(e, {status: null})}>unblock</Button> }
                                                            </Col>
                                                        }
                                                    </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Email: </Col>
                                            <Col sm={12-labelSize}>
                                                {user.email ?
                                                    <span>{user.email}&nbsp;&nbsp;<Button color="link" className='p-0 align-baseline' onClick={(e) => updateCurrentUser(e, {email: ''})}>clear</Button></span>
                                                    : <span>none</span>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Date Of Birth:</Col>
                                            <Col sm={12-labelSize}>
                                                { isDateOfBirthEditing ?
                                                <Row>
                                                    <Col sm={6}>
                                                        <Input type="date" name="date" max={moment().format('YYYY-MM-DD')} defaultValue={moment.utc(dabblUser.dateofbirth).format('YYYY-MM-DD')}
                                                                onChange={(e) => {setDateOfBirth(moment.utc(e.target.value).format('YYYY-MM-DD'))}}/>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Button color="primary"  disabled={!dateOfBirth}
                                                                onClick={(e) => {setIsDateOfBirthEditing(false);updateCurrentUser(e, {dateofbirth: dateOfBirth})}}>
                                                            Update
                                                        </Button>&nbsp;
                                                        <Button color="secondary" onClick={(e) => {setIsDateOfBirthEditing(false);e.preventDefault();}}>Cancel</Button>
                                                    </Col>
                                                </Row> :
                                                    <span>
                                                        {moment.utc(user.dateofbirth).format('YYYY-MM-DD')} &nbsp;
                                                        <Button color="link" className='p-0 align-baseline' onClick={(e) => editDateOfBirth(e)}>edit</Button>
                                                    </span>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Created: </Col>
                                            <Col sm={12-labelSize}> {user.createddate != null ? moment.utc(user.createddate).format('YYYY-MM-DD HH:mm') : '-' } </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Referred From: </Col>
                                            <Col sm={12-labelSize}> {user.referredfrom || 'None' } </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Lifetime Earnings: </Col>
                                            <Col sm={12-labelSize}> {user.lifetimeuse } </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Current Balance: </Col>
                                            <Col sm={12-labelSize}> { customerdata === null ? 'Loading...' : `$${customerdata.couponbufferbalance}` } </Col>
                                        </Row>
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Total Gift Card Amount: </Col>
                                            <Col sm={12-labelSize}> { totalGiftCardAmount === null ? 'Loading...' : `$${totalGiftCardAmount}` } </Col>
                                        </Row>
                                        {customerdata && customerdata.loyaltyid.length === 12 &&
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Active Coupon(SR only): </Col>

                                            <Col sm={12 - labelSize}>
                                            { customerdata.couponvalue > 0
                                            ?
                                                <Row>
                                                <Col sm={8}>
                                                    {`$${Number(customerdata.couponvalue).toFixed(0)}, exp: ${moment.utc(customerdata.couponexpiration).format('YYYY-MM-DD')}, #${customerdata.couponid}`} &nbsp;
                                                    <Button color="link" className='p-0 align-baseline' onClick={(e) => clearCoupon(e, {status: null})}>clear</Button>
                                                </Col>
                                                </Row>
                                            :
                                                <Row><Col sm={12-labelSize}> None </Col></Row>
                                        }
                                            </Col>
                                        </Row>
                                        }
                                        <Row>
                                            <Col className='font-weight-bold' sm={labelSize}>Earnings Breakdown: </Col>
                                            { userearnings === null
                                                ? <Col sm={12 - labelSize}>Loading...</Col>
                                                :
                                                <Fade in={userearnings !== null} tag={Col} className='border' sm={12-labelSize} >
                                                    {getEarnignsHeaders()}
                                                    {userearnings.map((earning, index) => getEarnignsRow(earning))}
                                                    {getEarnignsRow(userearnings.reduce((a, b) => ({...a, maindb: (+a.maindb||0) + (+b.maindb||0), esevent: (+a.esevent||0) + (+b.esevent||0)}), {earningtype: 'total'}))}
                                                </Fade>
                                            }
                                        </Row>
                                    </Col>
                                }
                            </Fade>
                            { userUpdateMessage && <span className="text-success">{userUpdateMessage}</span> }
                            {user && !user.id && <Alert color="warning" className='mt-3'> User Not Found... </Alert>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            { user && user.id &&
            <Row className='tabsection'>
                <Col sm={{ size: 10, offset: 1 }}>
                    <Nav tabs>
                        { Object.entries(userActions).map(([key, value])=>
                            <NavItem key={key}>
                                <NavLink className={classnames({ active: actionToShow.tabId === value.tabId })} onClick={() => { setActionToShow(value); }}>
                                    {value.title}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={actionToShow.tabId}>
                        <TabPane tabId={userActions.HISTORY.tabId}>
                            {user && userHistoryData && userHistoryData.length > 0 &&
                            <SupportHistoryByUser data={userHistoryData}/>
                            }
                        </TabPane>
                        <TabPane tabId={userActions.EARNING.tabId}>
                            <AddEarnings onAmountAdded={()=>onAmountAdded()} verifiedPurchases={verifiedPurchases}/>
                        </TabPane>
                        <TabPane tabId={userActions.IVT.tabId}>
                        <Row>
                            { ivtData && ivtData.length > 0 && ivtData[0].userwhitelisted ? <Col className='text-success ml-4'>User whitelisted</Col>
                                : <Col>
                                    <Row>
                                        <Col sm={6}>
                                            <Input type="text" name="comment" id="comment"
                                                   placeholder='Comment or ticket number'
                                                   value={ ivtComment }
                                                   onChange={ (e) => setIvtComment(e.target.value)}
                                            />
                                        </Col>
                                        <Col>
                                            <Button color="primary"  onClick={(e) => {e.preventDefault();ivtWhitelist(null, user.id);}}>
                                                Whitelist User
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col><Button color="link" className='float-right' onClick={(e) => props.history.push({pathname:'/ivtPage', search:`phonenumber=${user.name}`})}>
                                View in IVT
                            </Button></Col>
                        </Row>
                        { ivtData && <IvtTable data={ivtData} whitelistIp={(ip) => {ivtWhitelist(ip)}}/> }
                    </TabPane>
                        <TabPane tabId={userActions.DEVICES.tabId}>
                            { userDevices && <DevicesTable data={userDevices}/> }
                        </TabPane>
                        <TabPane tabId={userActions.REFERRAL.tabId}>
                            <LinkReferral/>
                        </TabPane>
                        <TabPane tabId={userActions.RETAILERS.tabId}>
                            <Retailers />
                        </TabPane>
                        <TabPane tabId={userActions.VERIFIED_PURCHASES.tabId}>
                            <VerifiedPurchases  verifiedPurchases={verifiedPurchases} />
                        </TabPane>
                        <TabPane tabId={userActions.CARDORDER.tabId}>
                            <GiftCards />
                        </TabPane>
                        <TabPane tabId={userActions.MISCELLANEOUS.tabId}>
                            {/*<Row>*/}
                            {/*    <Button color='primary' onClick={(e)=>fixDabblResearchIssue(e)}*/}
                            {/*            disabled={isFixDabblProcessing}*/}
                            {/*    >{`${isFixDabblProcessing ? 'Fixing...' : 'Fix'} Dabbl Reseach Issue`}</Button> &nbsp; &nbsp;*/}
                            {/*    {fixDabblMessage && <span>{fixDabblMessage}</span> }*/}
                            {/*</Row>*/}

                            {dabblUser && dabblUser.name.indexOf('_del') === -1 &&
                            <Row>
                                <Button color='primary' onClick={(e) => deleteCurrentUser(e, dabblUser.name)}
                                        disabled={isDeleteProcessing}
                                >{`${isDeleteProcessing ? 'Deleting...' : 'Delete Current User'}`}</Button> &nbsp; &nbsp;
                                {deleteResultMessage && <span>{deleteResultMessage}</span>}
                            </Row>
                            }
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            }
        </Container>
    )
}

export default UserLookup;
