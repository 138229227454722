import React from "react";
import {Button, Table, tbody, th, thead, tr} from "reactstrap";
import moment from "moment";

function IvtTable(props) {
    console.log('1231')
    console.log(props)
    const data = props.data;
    const wrapValue = (row, value) =>  {
        return props.lookupByFilter ?  <Button color="link" className='p-0 align-baseline' onClick={(e) => {e.preventDefault();props.lookupByFilter({[value]:row[value]}) }}>{row[value]}</Button>
            : <span>{row[value]}</span>
        }
    return <Table size="sm" borderless hover responsive>
        <thead>
        <tr>
            <th>Date</th>
            <th>Event</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Ip</th>
            <th>Device Id</th>
            <th>Fraud Type</th>
            <th>Score</th>
        </tr>
        </thead>
        <tbody>
        {data === null
            ? <tr>Loading...</tr>
            : data.map((row, index) => {
                return <tr key={index}>
                    <th scope="row">{moment.utc(row.createddate).format('YYYY-MM-DD HH:mm')}</th>
                    <td>{wrapValue(row, 'event')}</td>
                    <td>{wrapValue(row, 'phonenumber')}</td>
                    <td>{row.status}</td>
                    <td> <span>
                        { wrapValue(row, 'ip')}
                        {row.ipwhitelisted ?
                            <span>(whitelist)</span>
                            :
                            <span>
                                {props.whitelistIp && <Button color="link" className='p-0 align-baseline' onClick={(e) => {e.preventDefault();props.whitelistIp(row.ip); }}>Whitelist</Button>}
                            </span>
                        }
                        </span>
                    </td>
                    <td>{wrapValue(row, 'deviceid')}</td>
                    <td>{row.fraudtype}</td>
                    <td>{row.score}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export default IvtTable;